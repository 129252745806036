<template>
  <v-container
    ><v-layout row wrap justify-space-between class="mb-2">
      <v-flex xs12 sm4 md4 lg4 class="py-1 px-1">
        <v-btn
          :color="$store.state.secondaryColor"
          outlined
          small
          @click="goBack"
        >
          <v-icon small left>mdi-arrow-left</v-icon>
          <span class="caption">{{ $t("back") }}</span>
        </v-btn>
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex xs12 sm4 md4 lg4 class="py-1 px-1">
        <v-text-field
          v-model="search"
          dense
          solo
          :label="$t('search employees leave')"
        ></v-text-field>
      </v-flex>
    </v-layout>

    <v-layout row wrap justify-center v-if="employees">
      <v-flex xs12 sm6 md6 lg6 class="text-left">
        <v-avatar size="40">
          <v-img :src="employees.avatar" style="cursor: pointer">
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  width="5"
                  indeterminate
                  :color="$store.state.primaryColor"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-avatar>
        <span class="text-h5 font-weight-bold">
          {{
            employees.names.first +
            " " +
            employees.names.middle +
            " " +
            employees.names.last
          }}</span
        >
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex xs12 sm6 md6 lg6 class="text-right">
        <div v-if="leaveDetails.length != 0">
          <div v-if="userAccess == 1">
            <v-btn
              v-if="daysLeft > 1 && leaveDetails[0].requestStatus == 'approved'"
              class="ma-2"
              outlined
              small
              color="success"
            >
              {{ $t("on leave") }}
            </v-btn>
          </div>
          <div v-else>
            <div v-if="daysLeft < 1">
              <v-btn
                v-if="leaveDetails[0].requestStatus == 'pending'"
                class="ma-2"
                outlined
                small
                :color="$store.state.secondaryColor"
                @click="offerLeave()"
              >
                {{ $t("request leave") }}
              </v-btn>

              <v-btn v-else class="ma-2" outlined small color="success">
                {{ $t("enjoy your leave") }}
              </v-btn>
            </div>
            <v-btn
              v-else
              class="ma-2"
              outlined
              small
              :color="$store.state.secondaryColor"
              @click="offerLeave()"
            >
              {{ $t("request leave") }}
            </v-btn>
          </div>
        </div>
        <div v-else>
          <v-btn
            class="ma-2"
            outlined
            small
            :color="$store.state.secondaryColor"
            @click="offerLeave()"
          >
            {{ $t("request leave") }}
          </v-btn>
        </div>
      </v-flex>
    </v-layout>

    <v-layout row wrap justify-center>
      <v-flex xs12 sm12 md12 lg12>
        <v-data-table
          :headers="headers"
          :loading="loading"
          :items="leaveDetails"
          :search="search"
          :items-per-page="20"
        >
          <template v-slot:[`item.startDate`]="{ item }">
            <span>{{ formatDate(new Date(item.startDate)) }}</span>
          </template>
          <template v-slot:[`item.endDate`]="{ item }">
            <span>{{ formatDate(new Date(item.endDate)) }}</span>
          </template>
          <template v-slot:[`item.requestStatus`]="{ item }">
            <v-btn
              outlined
              x-small
              color="success"
              v-if="item.requestStatus == 'approved'"
            >
              {{ $t("approved") }}
            </v-btn>
            <v-btn
              outlined
              x-small
              :color="$store.state.secondaryColor"
              v-else-if="item.requestStatus == 'pending'"
              >{{ $t("pending") }}
            </v-btn>
            <v-btn outlined x-small color="red" v-else
              >{{ $t("denied") }}
            </v-btn>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-btn
              v-if="userAccess == 1"
              small
              class="mx-1"
              :disabled="item.requestStatus == 'approved'"
              left
              color="success"
              @click="acceptLeave(item.id)"
              >{{ $t("approve") }}</v-btn
            >
            <v-btn
              v-if="userAccess == 1"
              small
              :disabled="item.requestStatus == 'denied'"
              class="mx-1 white--text"
              left
              color="red"
              @click="denyLeave(item.id)"
              >{{ $t("deny") }}</v-btn
            >
            <v-btn
              class="ma-2"
              outlined
              x-small
              :color="$store.state.secondaryColor"
              @click="leaveDetailsPage(item.id)"
            >
              {{ $t("leave details") }}
            </v-btn>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <v-dialog v-model="leaveFormDialog" persistent max-width="700px">
      <v-card>
        <v-toolbar class="text-h5 font-weight-black" color="primary" dark>
          <v-layout row wrap
            ><v-flex xs12 sm6 md6 lg6>{{ $t("leave form") }}</v-flex>
            <v-flex xs12 sm6 md6 lg6 class="text-right pr-3">
              {{ leave.numberOfDays }} {{ $t("days") }}</v-flex
            >
          </v-layout>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="form">
              <v-layout row wrap>
                <v-flex xs12 sm12 md6 lg6 class="px-1">
                  <span class="font-weight-medium black--text"
                    >{{ $t("days on leave") }} :
                  </span>
                  <v-dialog
                    ref="dialog"
                    v-model="modal"
                    :return-value.sync="leaveRange"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="leaveRange"
                        append-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        dense
                        in
                        outlined
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="leaveRange"
                      :min="todaysDate"
                      scrollable
                      range
                      @change="calculateNumberOfDays()"
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="modal = false">
                        {{ $t("cancel") }}
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dialog.save(leaveRange)"
                      >
                        {{ $t("ok") }}
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-flex>

                <v-flex xs12 sm12 md6 lg6 class="px-1">
                  <span class="font-weight-medium black--text"
                    >{{ $t("reason for leave") }}:
                  </span>
                  <v-autocomplete
                    v-model="leave.leaveType"
                    :items="leaveTypes"
                    item-text="name"
                    item-value="name"
                    dense
                    outlined
                    :rules="rules.emptyField"
                  ></v-autocomplete>
                </v-flex> </v-layout
            ></v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn
            :color="$store.state.secondaryColor"
            text
            small
            @click="leaveFormDialog = false"
          >
            {{ $t("cancel") }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            :color="$store.state.primaryColor"
            text
            :loading="loading"
            small
            @click="submitLeaveRequest()"
          >
            {{ $t("confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      color="success lighten-5 success--text text--darken-3"
      top
      center
      v-model="snackbarSuccess"
    >
      {{ $t("employee leave status successfully updated") }} !
      <template v-slot:action="{ attrs }">
        <v-btn
          icon
          color="success darken-3"
          v-bind="attrs"
          @click="snackbarSuccess = false"
          :timeout="4000"
        >
          <v-icon> Close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="snackbarError"
      top
      center
      color="red lighten-5 error--text text--darken-3"
      :timeout="4000"
    >
      <span>{{ $t("could not update leave status. Try again later") }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          icon
          color="error darken-3"
          @click="snackbarError = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="approveLeaveDialog" persistent max-width="290">
      <v-card>
        <div class="text-center">
          <v-btn class="my-5" outlined large fab color="red darken-4">
            <v-icon>warning</v-icon>
          </v-btn>
        </div>

        <v-card-text class="text-center">
          <p>{{ $t("are you sure") }}?</p>
          <p>
            {{ $t("do you really want to approve leave for") }}
            <span class="font-weight-bold">{{
              employees.names.first +
              " " +
              employees.names.middle +
              " " +
              employees.names.last
            }}</span
            >?
          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn
            :color="$store.state.secondaryColor"
            text
            small
            @click="approveLeaveDialog = false"
          >
            {{ $t("cancel") }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            :color="$store.state.primaryColor"
            text
            :loading="loading"
            small
            @click="updateLeaveStatus()"
          >
            {{ $t("confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="denyLeaveDialog" persistent max-width="290">
      <v-card>
        <div class="text-center">
          <v-btn class="my-5" outlined large fab color="red darken-4">
            <v-icon>warning</v-icon>
          </v-btn>
        </div>

        <v-card-text class="text-center">
          <p>{{ $t("are you sure") }}?</p>
          <p>
            {{ $t("do you really want to deny leave for") }}
            <span class="font-weight-bold">{{
              employees.names.first +
              " " +
              employees.names.middle +
              " " +
              employees.names.last
            }}</span
            >?
          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn
            :color="$store.state.secondaryColor"
            text
            small
            @click="denyLeaveDialog = false"
          >
            {{ $t("cancel") }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            :color="$store.state.primaryColor"
            text
            :loading="loading"
            small
            @click="denyLeaveStatus()"
          >
            {{ $t("confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ########### -->
  </v-container>
</template>

<script>
import jwtdecode from "jwt-decode";
import { digits } from "@/plugins/functions";
import { format, differenceInDays } from "date-fns";
import db from "@/plugins/fb";
export default {
  data: () => ({
    todaysDate: format(new Date(), "yyyy-MM-dd"),
    modal: false,
    loading: true,
    leaveFormDialog: false,
    approveLeaveDialog: false,
    denyLeaveDialog: false,
    employeeName: "",
    search: "",
    leaveDetails: [],
    daysLeft: "",
    leaveRange: [],
    leave: {
      employeeId: "",
      startDate: "",
      endDate: "",
      numberOfDays: 0,
      leaveType: "",
      requestStatus: "pending",
      name: {},
    },
    employees: [],
    snackbarSuccess: false,
    snackbarError: false,
  }),

  computed: {
    ownersBusinessesId() {
      return this.$store.getters.getBusinessId;
    },

    ownersBusinesses() {
      return this.$store.getters.getBusinesses;
    },
    headers() {
      return [
        {
          text: this.$t("employee ID"),
          value: "employeeId",
          class: "blue-grey darken-3 white--text font-weight-regular",
        },
        {
          text: this.$t("number of days"),
          value: "numberOfDays",
          class: "blue-grey darken-3 white--text font-weight-regular",
          sortable: false,
        },
        {
          text: this.$t("start date"),
          value: "startDate",
          class: "blue-grey darken-3 white--text font-weight-regular",
          sortable: false,
        },
        {
          text: this.$t("end date"),
          value: "endDate",
          class: "blue-grey darken-3 white--text font-weight-regular",
          sortable: false,
        },
        {
          text: this.$t("request status"),
          value: "requestStatus",
          class: "blue-grey darken-3 white--text font-weight-regular",
          sortable: false,
        },
        {
          text: this.$t("type of leave"),
          value: "leaveType",
          class: "blue-grey darken-3 white--text font-weight-regular",
          sortable: false,
        },
        {
          text: this.$t("action"),
          value: "action",
          class: "blue-grey darken-3 white--text font-weight-regular",
          sortable: false,
          filterable: false,
        },
      ];
    },
    leaveTypes() {
      return [
        { name: this.$t("sick leave") },
        { name: this.$t("paternity leave") },
        { name: this.$t("maternity leave") },
        { name: this.$t("bereavement leave") },
        { name: this.$t("compensatory leave") },
        { name: this.$t("sabbatical leave") },
        { name: this.$t("unpaid leave") },
      ];
    },
    rules() {
      return {
        emptyField: [(v) => !!v || this.$t("this field is required")],
        number: [
          (v) => !!v || this.$t("this field is required"),
          (v) => !isNaN(v) || this.$t("please enter a number"),
        ],
      };
    },
  },

  created() {
    this.getEmployeeDetails();
    this.getLeaveDetails();
    this.checkAccessLevel();
  },

  methods: {
    digits,

    checkAccessLevel() {
      //Fetching current user details
      var data = jwtdecode(
        this.$store.state.token,
        this.$store.state.accessKey
      );
      this.userAccess = data.accessLevel.isSuperAdmin
        ? 0
        : data.accessLevel.isOwner
        ? 1
        : data.accessLevel.isEmployee
        ? 2
        : 3;
    },
    fullName(obj) {
      for (let x in obj) {
        return obj[x] + " ";
      }
    },

    viewIndividual(empID) {
      this.$router.push({ name: "ViewIndividual", params: { id: empID } });
    },

    editEmployee(empID) {
      this.$router.push({ name: "EditEmployee", params: { id: empID } });
    },

    getBusinessName(id) {
      let busId = this.$store.state.businesses.find((item) => item.id == id);
      let busName = busId.businessName;
      return busName;
    },

    getEmployeeDetails() {
      db.collection("employees")
        .doc(this.$route.params.id)
        .get()
        .then((doc) => {
          this.employees = doc.data();
          this.loading = false;
          this.leave.name.first = this.employees.names.first;
          this.leave.name.middle = this.employees.names.middle;
          this.leave.name.last = this.employees.names.last;
          this.leave.businessId = this.employees.businessId;
          this.leave.avatar = this.employees.avatar;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    getLeaveDetails() {
      this.leaveDetails = [];
      db.collection("leaves")
        .orderBy("endDate")
        .where("employeeId", "==", this.$route.params.id)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.leaveDetails.push({
              id: doc.id,
              ...doc.data(),
            });
            this.calculateLeaveStatus();
          });
        });
    },

    offerLeave() {
      this.leaveFormDialog = true;
    },

    submitLeaveRequest() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.leave.employeeId = this.$route.params.id;
        db.collection("leaves")
          .add(this.leave)
          .then(() => {
            this.loading = false;
            this.leaveFormDialog = false;
            this.snackbarSuccess = true;
            this.getLeaveDetails();
            this.getEmployeeDetails();
          })
          .catch(() => {
            this.leaveFormDialog = false;
            this.snackbarError = true;
          });
      }
    },

    calculateLeaveStatus() {
      if (this.leaveDetails) {
        this.todaysDate = format(new Date(), "yyyy-MM-dd");

        this.daysLeft = differenceInDays(
          new Date(
            this.leaveDetails[0].endDate.substring(0, 4),
            this.leaveDetails[0].endDate.substring(5, 7),
            this.leaveDetails[0].endDate.substring(8, 10)
          ),
          new Date(
            this.todaysDate.substring(0, 4),
            this.todaysDate.substring(5, 7),
            this.todaysDate.substring(8, 10)
          )
        );

        console.log("days left");
        console.log(this.daysLeft);
      }
    },

    //HR approves leave
    updateLeaveStatus() {
      this.loading = true;
      db.collection("leaves")
        .doc(this.leaveId)
        .update({
          requestStatus: "approved",
        })
        .then(() => {
          this.loading = false;
          this.approveLeaveDialog = false;
          this.snackbarSuccess = true;
          this.getLeaveDetails();
          this.getEmployeeDetails();
        })
        .catch(() => {
          this.approveLeaveDialog = false;
          this.snackbarError = true;
        });
    },

    //HR deny leave
    denyLeaveStatus() {
      this.loading = true;
      db.collection("leaves")
        .doc(this.leaveId)
        .update({
          requestStatus: "denied",
        })
        .then(() => {
          this.loading = false;
          this.denyLeaveDialog = false;
          this.snackbarSuccess = true;
          this.getLeaveDetails();
          this.getEmployeeDetails();
        })
        .catch(() => {
          this.denyLeaveDialog = false;
          this.snackbarError = true;
        });
    },

    calculateNumberOfDays() {
      this.leave.startDate = this.leaveRange[0];
      this.leave.endDate = this.leaveRange[1];

      this.leave.numberOfDays = differenceInDays(
        new Date(
          this.leave.endDate.substring(0, 4),
          this.leave.endDate.substring(5, 7),
          this.leave.endDate.substring(8, 10)
        ),
        new Date(
          this.leave.startDate.substring(0, 4),
          this.leave.startDate.substring(5, 7),
          this.leave.startDate.substring(8, 10)
        )
      );
    },
    acceptLeave(id) {
      console.log(id);
      this.leaveId = id;
      this.approveLeaveDialog = true;
    },

    denyLeave(id) {
      console.log(id);
      this.leaveId = id;
      this.denyLeaveDialog = true;
    },
    formatDate(val) {
      return format(val, "EEE, dd MMMM, yyyy");
    },
    leaveDetailsPage(id) {
      this.$router.push({
        name: "ViewLeaveDetails",
        params: { id: id },
      });
    },
    goBack() {
      window.history.back();
    },
  },
};
</script>

<style></style>
